import store from '@store/store';
import { selectUIDisplayState } from '@store/rooms';
import { selectDeviceType } from '@store/tour';

export const eventHandler = (
  eventID: string,
  eventList: any,
  history: any
): void => {
  console.log(eventID);
  if (!eventList) return;
  const state = store.getState();
  const event = eventList.filter(
    (eventObject) => eventObject.id === eventID
  )[0];
  if (eventID === 'roam_off') {
    window.dispatchEvent(new Event('next-event'));
    store.dispatch({
      type: 'rooms/setInputAllowed',
      payload: false,
    });
  } else if (eventID === 'roam_on') {
    window.dispatchEvent(new Event('next-event'));
    store.dispatch({
      type: 'rooms/setInputAllowed',
      payload: true,
    });
  } else if (eventID === 'toggle-ui') {
    const uiState = selectUIDisplayState(state);
    window.dispatchEvent(new Event('next-event'));
    store.dispatch({
      type: 'rooms/setUIDisplayState',
      payload: !uiState,
    });
  } else if (eventID === 'timeout') {
    setTimeout(() => {
      window.dispatchEvent(new Event('next-event'));
    }, 10000);
  } else if (eventID === 'short-timeout') {
    setTimeout(() => {
      window.dispatchEvent(new Event('next-event'));
    }, 1000);
  } else if (eventID === 'long-timeout') {
    setTimeout(() => {
      window.dispatchEvent(new Event('next-event'));
    }, 20000);
  } else if (eventID === 'toggle-fade') {
    const roomEl = document.getElementById('room');
    if (roomEl) {
      roomEl.classList.toggle('cover-black');
      window.dispatchEvent(new Event('next-event'));
    }
  } else {
    switch (event.type) {
      case 'change-room':
        // fade out
        const roomEl = document.getElementById('room');
        if (roomEl) {
          roomEl.classList.add('cover-black');
        }
        setTimeout(() => {
          if (history) {
            const currentPath = window.location.pathname;
            const basePath = `/${currentPath.split('/')[1]}/`;
            history.push(basePath + event.room);
          }
        }, 500);
        break;
      case 'guide-video':
        const guideVideoContainer = document.getElementById(
          'guide-video-container'
        );
        const guideVideo = document.getElementById('guide-video');
        const sources = guideVideo?.querySelectorAll('source');
        const videoTrack = guideVideo?.querySelector('track');
        const mapButton = document.getElementById('map-button');
        const dynamicImage = document.getElementById('dynamic-image');
        if (
          guideVideo instanceof HTMLVideoElement &&
          guideVideoContainer &&
          videoTrack instanceof HTMLTrackElement &&
          mapButton
        ) {
          guideVideo.load();

          mapButton.classList.add('hide');
          guideVideoContainer.classList.remove('hide');

          let { source } = event;
          const { responsive } = event;
          if (responsive) {
            const deviceType = selectDeviceType(state);
            switch (deviceType) {
              case 'mobile':
                source = event.mobile;
                break;
              default:
                break;
            }
          }
          if (sources && sources.length === 3) {
            sources[0].src = `/video/guide/${source}.webm`;
            sources[1].src = `/video/guide/${source}.mp4`;
            sources[2].src = `/video/guide/${source}.mov`;
          }
          // guideVideo.src = `./video/${source}.webm`;
          if (event.subtitles) {
            videoTrack.src = `/video/captions/${source}.vtt`;
          } else {
            videoTrack.src = '';
          }
          guideVideo.volume = 1;
          guideVideo.load();

          if (event.dynamicImage && dynamicImage) {
            store.dispatch({
              type: 'rooms/setDynamicImageInfo',
              payload: {
                source: event.dynamicImage.source,
                position: {
                  x: event.dynamicImage.position.x,
                  y: event.dynamicImage.position.y,
                },
                size: {
                  width: event.dynamicImage.size.width,
                  height: event.dynamicImage.size.height,
                },
                time: event.dynamicImage.time || 0,
              },
            });

            if (event.dynamicImage.time) {
              const timeout = window.setTimeout(() => {
                dynamicImage.classList.remove('hide');
              }, event.dynamicImage.time * 1000);
              window.timeout = timeout;
            } else {
              dynamicImage.classList.remove('hide');
            }
          }

          const endHandler = () => {
            guideVideo.removeEventListener('ended', endHandler);
            mapButton.classList.remove('hide');
            dynamicImage?.classList.add('hide');
            clearTimeout(window.timeout);
            window.dispatchEvent(new Event('next-event'));
          };
          guideVideo.addEventListener('ended', endHandler);
        }
        break;
      case 'transition-video':
        store.dispatch({
          type: 'display/setVideoDisplayState',
          payload: true,
        });
        store.dispatch({
          type: 'display/setVideoSource',
          payload: `/video/transitions/${event.source}`,
        });
        const transitionVideo = document.getElementById('fullscreen-video');
        if (transitionVideo instanceof HTMLVideoElement) {
          transitionVideo.play();
        }
        break;
      case 'change-photosphere':
        store.dispatch({
          type: 'display/setVideoDisplayState',
          payload: true,
        });
        store.dispatch({
          type: 'display/setVideoSource',
          payload: `/video/transitions/${event.source}`,
        });
        const fullscreenVideo = document.getElementById('fullscreen-video');
        if (fullscreenVideo instanceof HTMLVideoElement) {
          fullscreenVideo.play();
        }
        setTimeout(() => {
          if (window.viewer) {
            const { viewer } = window;
            viewer.setPanorama(`/img/panorama/${event.photosphere}`);

            const { models } = state.tour;
            models.forEach((model) => {
              const object = viewer.renderer.scene.getObjectByName(model.id);
              if (object) {
                if (object.visible) object.visible = false;
                else object.visible = true;
                viewer.needsUpdate();
              }
            });
          }
        }, 1000);
        break;
      case 'modal':
        const modalWrapper = document.getElementById('modal-wrapper');
        modalWrapper?.classList.remove('hide');
        if (event.modalInfo) {
          store.dispatch({
            type: 'rooms/setModalInfo',
            payload: {
              title: event.modalInfo.title,
              text: event.modalInfo.text || '',
              image: event.modalInfo.image || '',
            },
          });
        }
        break;
      case 'toggle-markers':
        const { markersPlugin } = window;
        const { markers } = event;
        if (!markersPlugin) return;
        markers.forEach((markerID) => {
          markersPlugin.toggleMarker(markerID);
        });
        break;
      default:
        break;
    }
  }
};
