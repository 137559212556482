import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

import { createCameraMove, createCameraLook } from '@functions/cameraHandling';

interface Coordinates {
  x: number;
  y: number;
  z: number;
}

interface TargetCoordinates {
  x?: number;
  y?: number;
  z?: number;
}

interface CameraState {
  position: Coordinates;
  initialLookPoint: Coordinates;
  rotationDir: string | null;
  movementDir: string | null;
}

const initialState: CameraState = {
  position: { x: 0, y: 1.5, z: 0 },
  initialLookPoint: { x: 0, y: 0, z: 0 },
  rotationDir: null,
  movementDir: null,
};

export const cameraSlice = createSlice({
  name: 'camera3D',
  initialState,
  reducers: {
    moveCameraToTarget: (state, action: PayloadAction<TargetCoordinates>) => {
      createCameraMove(action.payload).start();
    },
    lookAtTarget: (state, action: PayloadAction<TargetCoordinates>) => {
      createCameraLook(action.payload).start();
    },
    setPosition: (state, action: PayloadAction<Coordinates>) => {
      state.position = action.payload;
    },
    setInitialLookPoint: (state, action: PayloadAction<Coordinates>) => {
      state.initialLookPoint = action.payload;
    },
    setRotationDir: (state, action: PayloadAction<string | null>) => {
      state.rotationDir = action.payload;
    },
    setMovementDir: (state, action: PayloadAction<string | null>) => {
      state.movementDir = action.payload;
    },
  },
});

export const {
  moveCameraToTarget,
  setPosition,
  lookAtTarget,
  setRotationDir,
  setMovementDir,
} = cameraSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPosition = (state: RootState): Coordinates =>
  state.camera3D.position;
export const selectRotatingDirValue = (state: RootState): string | null =>
  state.camera3D.rotationDir;
export const selectMovementDirValue = (state: RootState): string | null =>
  state.camera3D.movementDir;
export const selectInitialLookPoint = (state: RootState): Coordinates =>
  state.camera3D.initialLookPoint;

export default cameraSlice.reducer;
