import React from 'react';
import { RoomContainer } from './Room.styled';

import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectRooms } from '@store/tour';
import { useAppDispatch } from '@store/store';

import UI from '@components/UI';
import Photosphere from '@components/Photosphere';
import FullscreenVideo from '@components/FullscreenVideo';

import { updateCurrentTour } from '@functions/updateCurrentTour';
import { setCurrentRoom } from '@store/tour';
import { sequenceHandler } from '@functions/sequenceHandler';

export const Room = () => {
  const dispatch = useAppDispatch();
  const { room: roomID, tour: tourID } = useParams<{
    room: string;
    tour: string;
  }>();
  const [roomData, setRoomData] = React.useState<any>();
  const [redirect, setRedirect] = React.useState(false);

  const rooms = useSelector(selectRooms);

  React.useEffect(() => {
    updateCurrentTour(tourID);
  }, []);

  React.useEffect(() => {
    if (rooms.length) {
      const currentRoomData = rooms.filter((room) => room.id === roomID)[0];
      if (currentRoomData) {
        setRoomData(currentRoomData);
        dispatch(setCurrentRoom(currentRoomData));

        const { sequences } = currentRoomData.scripting;
        if (sequences) {
          const autoplayingSequences = sequences.filter(
            (sequence) => sequence.autoplay
          );
          autoplayingSequences.forEach((autoplaySeq) => {
            sequenceHandler(autoplaySeq.id, currentRoomData);
          });
        }
      } else setRedirect(true);
    }
  }, [rooms.length]);

  return (
    <RoomContainer id="room" className="cover-black">
      {redirect && <Redirect to="/404" />}
      {roomData && (
        <React.Fragment>
          <FullscreenVideo />
          <UI room={roomData} />
          <Photosphere room={roomData} />
        </React.Fragment>
      )}
    </RoomContainer>
  );
};
