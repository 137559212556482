import React from 'react';
import { FullscreenVideoEl } from './FullscreenVideo.styled';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/store';
import {
  selectVideoState,
  setVideoDisplayState,
  setVideoSource,
} from '@store/display';

export const FullscreenVideo = () => {
  const dispatch = useAppDispatch();
  const videoDetails = useSelector(selectVideoState);
  const fullscreenVideoEl = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (fullscreenVideoEl && fullscreenVideoEl.current) {
      if (
        fullscreenVideoEl.current instanceof HTMLVideoElement &&
        fullscreenVideoEl.current.src.includes('.mp4')
      ) {
        console.log(fullscreenVideoEl.current.src);
        fullscreenVideoEl.current.play();
      }
      fullscreenVideoEl.current.addEventListener('ended', () => {
        dispatch(setVideoDisplayState(false));
        dispatch(setVideoSource(''));
        window.viewer.needsUpdate();
        window.dispatchEvent(new Event('next-event'));
      });

      fullscreenVideoEl.current.addEventListener('click', function play() {
        fullscreenVideoEl.current?.play();
      });
    }
  }, [fullscreenVideoEl.current]);
  return (
    <FullscreenVideoEl
      id="fullscreen-video"
      src={videoDetails.source || ''}
      visible={videoDetails.display}
      ref={fullscreenVideoEl}
      disableRemotePlayback={true}
      playsInline={true}
      preload="auto"
    />
  );
};
