import styled from 'styled-components';

export const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: center;
  background: #393939;

  @media only screen and (max-width: 767px) and (orientation: portrait) {
    display: none;
  }
`;

export const PortraitWarning = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #393939;

  @media only screen and (max-width: 767px) and (orientation: portrait) {
    display: flex;
  }

  h1 {
    text-align: center;
    color: white;
  }
`;
