import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const OnboardingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #393939;
`;

export const InnerContainer = styled.div`
  position: relative;
  width: 50%;
  height: 70%;
  background: white;

  @media only screen and (max-width: 1024px) {
    width: 80%;
    height: 90%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 40px 80px;

  @media only screen and (max-width: 1024px) {
    padding: 20px 30px;
  }
`;

export const Title = styled.h1`
  font-family: 'Argesta Display';
  text-transform: uppercase;
  color: #0e3453;

  span {
    font-size: 50px;
    display: block;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 18px;

    span {
      font-size: 24px;
    }
  }
`;

export const Loader = styled.div`
  position: relative;
  width: 10em;
  height: 10em;
  margin: 60px auto;
  font-size: 10px;
  text-indent: -9999em;
  border: 1.1em solid rgba(14, 52, 83, 0.2);
  border-left: 1.1em solid #0e3453;
  transform: translateZ(0);
  animation: load 1.1s infinite linear;
  border-radius: 50%;

  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @media only screen and (max-width: 1024px) {
    width: 50px;
    height: 50px;
    margin: 20px auto;
  }

  @keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const Progress = styled.div`
  display: inline-block;
  padding: 0 50px 30px;
  border-bottom: 1px solid #0e3453;
  p {
    margin: 0;
    font-family: 'Argesta Text';
    text-transform: uppercase;
    color: #0e3453;
    font-size: 20px;

    &.percent {
      font-size: 36px;
      margin-bottom: 15px;
    }
  }
`;

export const Introduction = styled.div`
  .heading {
    margin-top: 0;
    font-family: 'Argesta Display';
    text-transform: uppercase;
    color: #0e3453;
    font-size: 32px;

    @media only screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }

  p {
    font-family: 'Argesta Text';
    color: #0e3453;
    font-size: 24px;
    font-weight: bold;

    @media only screen and (max-width: 1024px) {
      font-size: 18px;
    }
  }

  img {
    @media only screen and (max-width: 1024px) {
      height: 50px;
    }
  }
`;

export const StartButton = styled(Link)`
  width: 50%;
  margin: 30px auto 0;
  padding: 10px 60px 10px 30px;
  background-color: white;
  background-image: url('./img/ui/arrow.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: 60px;
  border: 1px solid #0e3453;
  font-family: 'Argesta Display';
  text-transform: uppercase;
  text-decoration: none;
  color: #0e3453;
  font-size: 32px;
  font-weight: bold;
  transition: background 0.5s ease;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 0;
  }
`;
