import styled from 'styled-components';

export const RoomContainer = styled.div`
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
    z-index: 100;
  }

  &.cover-black {
    &::after {
      opacity: 1;
    }
  }
`;
