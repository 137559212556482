import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

interface LatLonCoordinates {
  lat: number;
  lon: number;
}

interface CameraState {
  look: LatLonCoordinates;
  rotationDir: string | null;
}

const initialState: CameraState = {
  look: { lat: 0, lon: 0 },
  rotationDir: null,
};

export const cameraPhotosphereSlice = createSlice({
  name: 'cameraPhotosphere',
  initialState,
  reducers: {
    storeLookPoint: (state, action: PayloadAction<LatLonCoordinates>) => {
      state.look = action.payload;
    },
    rotateCamera: (state, action: PayloadAction<string>) => {
      const { viewer } = window;
      if (!viewer) return;
      const { lat } = state.look;
      let { lon } = state.look;

      if (action.payload === 'cw') {
        lon += Math.PI / 4;
        if (lon >= 2 * Math.PI) lon -= 2 * Math.PI;
      } else if (action.payload === 'ccw') {
        lon -= Math.PI / 4;
        if (lon <= 0) lon += 2 * Math.PI;
      }
      viewer.animate({
        longitude: lon,
        latitude: lat,
        speed: '5rpm',
      });
    },
  },
});

export const { storeLookPoint, rotateCamera } = cameraPhotosphereSlice.actions;

export const selectLookPoint = (state: RootState): LatLonCoordinates =>
  state.cameraPhotosphere.look;

export default cameraPhotosphereSlice.reducer;
