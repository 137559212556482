import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

const global = css`
  body {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.baseFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
  }

  body *,
  body *::before,
  body *::after {
    border-collapse: collapse;
    box-sizing: border-box;

    @media only screen and (max-width: 1024px) {
      outline: none;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  // Generic CSS Transition fade in/out
  .fade-enter {
    opacity: 0;
    transition: opacity 0.3s ease-in 0.3s;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-in 0.3s;
  }

  .fade-exit {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${global}
`;
