import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TourSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #393939;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: column;
  position: relative;
  width: 50%;
  height: 70%;
  padding: 40px;
  background: white;

  @media only screen and (max-width: 1024px) {
    width: 80%;
    height: 90%;
  }
`;

export const Title = styled.h1`
  position: relative;
  margin: 0 0 50px;
  padding-bottom: 20px;
  font-family: 'Argesta Display';
  text-transform: uppercase;
  color: #0e3453;
  font-size: 50px;

  @media only screen and (max-width: 1024px) {
    font-size: 24px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 35%;
    width: 30%;
    height: 2px;
    background-color: #0e3453;
  }
`;

export const TourLink = styled(Link)`
  width: 50%;
  margin: 0 auto 20px;
  padding: 10px 20px;
  border: 1px solid #0e3453;
  font-family: 'Argesta Display';
  text-transform: uppercase;
  color: #0e3453;
  font-size: 30px;
  text-decoration: none;
  background-color: white;
  transition: background 0.5s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;
