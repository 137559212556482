import styled from 'styled-components';

export const PhotosphereContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  z-index: 2;

  .psv-navbar {
    display: none;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.5s ease;
  opacity: 1;
  z-index: 30;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 75%;
  padding: 50px 100px;
  background-color: white;
  border-radius: 30px;
`;

export const ModalClose = styled.div`
  position: absolute;
  top: -35px;
  right: -35px;
  width: 70px;
  height: 70px;
  background-image: url('/img/ui/button-close-dark-normal.svg');
  background-position: center;
  transition: background 0.5s ease;

  &:hover {
    background-image: url('/img/ui/button-close-dark-hover.svg');
    cursor: pointer;
  }
`;

export const ModalTitle = styled.div`
  display: inline-block;
  position: relative;
  min-height: 0;
  margin-bottom: 35px;
  font-family: 'Argesta Display';
  text-transform: uppercase;

  h1,
  h2 {
    margin: 0 0 10px;
  }

  h1 {
    font-size: 50px;
    font-weight: bold;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -10%;
    left: 30%;
    width: 40%;
    height: 2px;
    background: black;
  }
`;

export const ModalContent = styled.div<{ dual?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.dual ? 'space-between' : 'flex-start')};
  min-height: 0;
`;

export const ModalImage = styled.img<{ dual?: boolean }>`
  width: ${(props) => (props.dual ? '48%' : '100%')};
  height: 100%;
  object-fit: contain;
  max-height: 50vh;
`;

export const ModalText = styled.p<{ dual?: boolean }>`
  width: ${(props) => (props.dual ? '48%' : '100%')};
  font-size: 20px;
  white-space: pre-wrap;
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Argesta Display';
  text-align: left;
`;

export const DynamicImageWrapper = styled.div<{
  dimensions: { top: number; left: number; width: number; height: number };
}>`
  position: absolute;
  top: ${(props) => props.dimensions.top + 'px'};
  left: ${(props) => props.dimensions.left + 'px'};
  width: ${(props) => props.dimensions.width + 'px'};
  height: ${(props) => props.dimensions.height + 'px'};
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: 30;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
