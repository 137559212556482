import React from 'react';
import { MapContainer, CloseButton, Title, MapView } from './Map.styled';

import { useSelector } from 'react-redux';
import { selectTourName, selectCurrentRoom, selectRooms } from '@store/tour';
import { useRouteMatch, Link } from 'react-router-dom';

interface Props {
  show: boolean;
  setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Map = ({ show, setShowMap }: Props) => {
  const tourName = useSelector(selectTourName);
  const currentRoom = useSelector(selectCurrentRoom);
  const allRooms = useSelector(selectRooms);

  const matches = useRouteMatch();
  let baseUrl = '';
  if (matches) baseUrl = matches.url.split('/')[1];

  return (
    <MapContainer data-testid="Map" show={show}>
      <CloseButton
        onClick={() => {
          setShowMap(false);
        }}
      />
      <Title>
        <span>Map View</span>
        {tourName}
      </Title>
      <MapView>
        {allRooms.map((room) => (
          <div
            key={room.id}
            className={`marker${
              room.id === currentRoom.id ? '' : ' marker-link'
            }`}
            style={{
              top: room?.mapPosition?.y || 0,
              left: room?.mapPosition?.x || 0,
            }}
          >
            {room.id === currentRoom.id ? (
              <img
                className="current-location"
                src="/img/ui/map-you-are-here.svg"
                alt=""
              />
            ) : (
              <React.Fragment>
                <span className="room-title">{room.title}</span>
                <Link
                  className="room-link"
                  to={`/${baseUrl}/${room.id}`}
                ></Link>
              </React.Fragment>
            )}
          </div>
        ))}
        <img className="background" src="/img/map-grand-tour.svg" alt="" />
      </MapView>
    </MapContainer>
  );
};
