import styled from 'styled-components';

export const FullscreenVideoEl = styled.video<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: 1s opacity linear;
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
  z-index: 101;
`;
