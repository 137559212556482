import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

export interface TourListItem {
  name: string;
  title: string;
}

type DeviceType =
  | 'desktop'
  | 'mobile'
  | 'vr'
  | 'cardboard'
  | 'cardboard-trigger';

interface TourState {
  id: string;
  name: string;
  slug: string;
  startRoom: string;
  rooms: {
    [key: string]: any;
  };
  currentRoom: any;
  models: any[];
  verified: boolean;
  deviceType: DeviceType;
}

const storedVerification = window.localStorage.getItem('verified');
const verified = storedVerification === 'true';
const initialState: TourState = {
  id: '',
  name: '',
  slug: '',
  startRoom: '',
  rooms: [],
  currentRoom: null,
  models: [],
  verified: verified || false,
  deviceType: 'desktop',
};

export const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTourID: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setTourName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setTourSlug: (state, action: PayloadAction<string>) => {
      state.slug = action.payload;
    },
    setTourStartRoom: (state, action: PayloadAction<string>) => {
      state.startRoom = action.payload;
    },
    setRooms: (state, action: PayloadAction<any>) => {
      state.rooms = action.payload;
    },
    setCurrentRoom: (state, action: PayloadAction<any>) => {
      state.currentRoom = action.payload;
    },
    setModels: (state, action: PayloadAction<any>) => {
      state.models = action.payload;
    },
    setVerified: (state, action: PayloadAction<boolean>) => {
      state.verified = action.payload;
    },
    setDeviceType: (state, action: PayloadAction<DeviceType>) => {
      state.deviceType = action.payload;
    },
  },
});

export const {
  setTourID,
  setTourName,
  setTourSlug,
  setTourStartRoom,
  setRooms,
  setCurrentRoom,
  setModels,
  setVerified,
  setDeviceType,
} = tourSlice.actions;

export const selectTourID = (state: RootState): string => state.tour.id;

export const selectTourName = (state: RootState): string => state.tour.name;

export const selectTourSlug = (state: RootState): string => state.tour.slug;

export const selectTourStartRoom = (state: RootState): string =>
  state.tour.startRoom;

export const selectRooms = (state: RootState): any => state.tour.rooms;

export const selectModels = (state: RootState): any => state.tour.models;

export const selectVerified = (state: RootState): boolean =>
  state.tour.verified;

export const selectCurrentRoom = (state: RootState): any =>
  state.tour.currentRoom;

export const selectDeviceType = (state: RootState): DeviceType =>
  state.tour.deviceType;

export default tourSlice.reducer;
