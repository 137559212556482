import styled from 'styled-components';

export const MapContainer = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 50px 0;
  background-color: white;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: ${(props) => (props.show ? 'all' : 'none')};
  z-index: 5;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
  width: 60px;
  height: 60px;
  background-image: url('/img/ui/button-close-dark-normal.svg');
  background-position: center;
  transition: background 0.5s ease;

  &:hover {
    background-image: url('/img/ui/button-close-dark-normal.svg');
    cursor: pointer;
  }
`;

export const Title = styled.h1`
  position: relative;
  margin: 0 0 25px;
  font-family: 'Argesta Display';
  text-transform: uppercase;
  color: #0e3453;
  font-size: 30px;

  span {
    display: block;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 2px solid #0e3453;
  }
`;

export const MapView = styled.div`
  position: relative;
  width: 80%;

  .marker {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 4%;
    height: 15%;

    &-link {
      flex-direction: column;
      justify-content: flex-start;
      transition: transform 0.5s ease;

      &:hover {
        cursor: pointer;

        .room-title {
          background: white;
          color: #0e3453;
        }
      }
    }
  }

  .room-title {
    padding: 10px;
    color: #b99155;
    font-family: 'Argesta Text';
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
    border-radius: 20px;
    transition: padding 0.5s ease, background 0.5s ease, color 0.5s ease;
  }

  .room-link {
    display: block;
    width: 25px;
    min-height: 25px;
    border: 2px solid white;
    background: #0e3453;
    border-radius: 50%;
  }

  .background {
    width: 100%;
    height: 100%;
  }
`;
