import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

export interface displayState {
  scene3DVisible: boolean;
  photosphereVisible: boolean;
  room: string;
  video: VideoDetails;
}
export interface VideoDetails {
  display: boolean;
  source: string;
}

const initialState: displayState = {
  scene3DVisible: true,
  photosphereVisible: false,
  room: 'ballroom',
  video: {
    display: false,
    source: '',
  },
};

export const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    swapTo3DScene: (state) => {
      state.scene3DVisible = true;
      state.photosphereVisible = false;
    },
    swapToPhotosphere: (state) => {
      state.scene3DVisible = false;
      state.photosphereVisible = true;
    },
    updateRoom: (state, action: PayloadAction<string>) => {
      const name = action.payload.substring(5);
      state.room = name;
    },
    setVideoDisplayState: (state, action: PayloadAction<boolean>) => {
      state.video.display = action.payload;
    },
    setVideoSource: (state, action: PayloadAction<string>) => {
      state.video.source = action.payload;
    },
  },
});

export const {
  swapTo3DScene,
  swapToPhotosphere,
  updateRoom,
  setVideoDisplayState,
  setVideoSource,
} = displaySlice.actions;

export const select3DSceneVisibility = (state: RootState): boolean =>
  state.display.scene3DVisible;

export const selectPhotosphereVisibility = (state: RootState): boolean =>
  state.display.photosphereVisible;

export const selectRoom = (state: RootState): string => state.display.room;

export const selectVideoState = (state: RootState): VideoDetails =>
  state.display.video;

export default displaySlice.reducer;
