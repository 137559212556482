import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

import { Object3D } from 'three';

export interface Model3D {
  id: string;
  filename: string;
  size: number;
  dataObject: Object3D | null;
}

interface InitialState {
  models: Model3D[];
}

const initialState: InitialState = {
  models: [],
};

export const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setModels: (state, action: PayloadAction<Model3D[]>) => {
      state.models = action.payload;
    },
    setSingleModel: (state, action: PayloadAction<Model3D>) => {
      const { models } = state;
      const newModel = action.payload;
      const newModelArray = models.map((model) => {
        if (model.id === newModel.id) {
          return newModel;
        } else return model;
      });
      state.models = newModelArray;
    },
  },
});

export const { setModels, setSingleModel } = modelSlice.actions;

export const selectModels = (state: RootState): Model3D[] =>
  state.models.models;

export default modelSlice.reducer;
