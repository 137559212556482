import { combineReducers } from '@reduxjs/toolkit';
import cameraReducer from './camera3D';
import displayReducer from './display';
import roomsReducer from './rooms';
import tourReducer from './tour';
import modelsReducer from './models';
import cameraPhotosphereReducer from './cameraPhotosphere';

const rootReducer = combineReducers({
  camera3D: cameraReducer,
  display: displayReducer,
  cameraPhotosphere: cameraPhotosphereReducer,
  rooms: roomsReducer,
  tour: tourReducer,
  models: modelsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
