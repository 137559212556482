import React from 'react';
import {
  UIContainer,
  TitleContainer,
  TitleTour,
  TitleRoom,
  ArrowContainer,
  Arrow,
  GuideVideoContainer,
  GuideVideo,
  GuideVideoClose,
  MarkerToggleContainer,
  MarkerToggleBar,
  MarkerToggleText,
  MarkerToggleIcon,
  MenuContainer,
  MenuToggle,
  MapButton,
} from './UI.styled';

import Map from '@components/Map';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setRotationDir, setMovementDir } from '@store/camera3D';
import { rotateCamera } from '@store/cameraPhotosphere';
import {
  checkDistanceToMarkers,
  RoomInformation,
  selectUIDisplayState,
} from '@store/rooms';
import { useAppDispatch } from '@store/store';
import { Version } from '@components/Version';
import { selectTourName, selectTourSlug } from '@store/tour';
interface Props {
  room: RoomInformation;
}

export const UI: React.FC<Props> = ({ room }) => {
  const dispatch = useAppDispatch();
  const displayUI = useSelector(selectUIDisplayState);
  const tourSlug = useSelector(selectTourSlug);
  const tourName = useSelector(selectTourName);
  const [markersShowing, setMarkersShowing] = React.useState(true);
  const [showMap, setShowMap] = React.useState(false);

  const dispatchCameraMove = (dir) => {
    if (room.type === '3D') {
      if (dir === 'cw' || dir === 'ccw') {
        dispatch(setRotationDir(dir));
        setTimeout(() => {
          dispatch(setRotationDir(null));
        }, 500);
      } else if (dir === 'forward') {
        dispatch(setMovementDir(dir));
        setTimeout(() => {
          dispatch(setMovementDir(null));
        }, 500);
      }

      dispatch(checkDistanceToMarkers());
    } else if (room.type === 'photosphere') {
      if (dir === 'cw' || dir === 'ccw') {
        console.log('test');
        dispatch(rotateCamera(dir));
      }
    }
  };

  React.useEffect(() => {
    const guideVideoContainer = document.getElementById(
      'guide-video-container'
    );
    const guideVideo = document.getElementById('guide-video');
    const sources = guideVideo?.querySelectorAll('source');
    const guideVideoClose = document.getElementById('guide-video-close');
    const mapButton = document.getElementById('map-button');
    const dynamicImageEl = document.getElementById('dynamic-image');
    if (
      guideVideo instanceof HTMLVideoElement &&
      guideVideoContainer &&
      guideVideoClose &&
      mapButton &&
      dynamicImageEl &&
      sources
    ) {
      guideVideo.addEventListener('ended', () => {
        guideVideoContainer.classList.add('hide');
        mapButton.classList.remove('hide');
      });
      guideVideo.addEventListener('canplaythrough', () => {
        guideVideo.play();
      });

      guideVideoClose.addEventListener('click', () => {
        guideVideo.pause();
        guideVideoContainer.classList.add('hide');
        const fadeAudio = setInterval(function () {
          // Only fade if past the fade out point or not at zero already
          if (guideVideo.volume - 0.1 >= 0) {
            guideVideo.volume -= 0.1;
          } else {
            clearInterval(fadeAudio);
            guideVideo.pause();
          }
        }, 50);
        mapButton.classList.remove('hide');
        clearTimeout(window.timeout);
        dynamicImageEl.classList.add('hide');
        sources.forEach((source) => {
          source.src = '';
        });
        window.dispatchEvent(new Event('next-event'));
      });
    }
  }, []);

  const toggleMarkers = () => {
    const markers = document.querySelectorAll('.psv-marker');
    setMarkersShowing(!markersShowing);
    markers.forEach((marker) => {
      marker.classList.toggle('hide');
    });
  };

  const toggleMenu = () => {
    const menuContainer = document.getElementById('menu-container');
    menuContainer?.classList.toggle('open');
  };

  const toggleMenuOption = (e) => {
    e.target.classList.toggle('off');
  };

  return (
    <UIContainer id="ui-container" className={`${displayUI ? '' : 'hide'}`}>
      <TitleContainer slug={tourSlug}>
        <TitleTour>{tourName}</TitleTour>
        <TitleRoom>{room.title}</TitleRoom>
      </TitleContainer>

      <MenuToggle onClick={toggleMenu} tabIndex={0} />
      <MenuContainer id="menu-container">
        <div className="header">
          <h2 className="header__title">Menu</h2>
          <div
            className="header__button"
            id="menu-close"
            onClick={toggleMenu}
          ></div>
        </div>
        <div className="menu-options">
          <div className="option">
            <div className="checkbox" onClick={toggleMenuOption}></div>
            <p>Captions</p>
          </div>
          <div className="option">
            <div className="checkbox" onClick={toggleMenuOption}></div>
            <p>Background audio</p>
          </div>
        </div>

        <Link to="/" className="exit-tour">
          Exit the tour
        </Link>
        <Version />
      </MenuContainer>

      <GuideVideoContainer className="hide" id="guide-video-container">
        <GuideVideo disableRemotePlayback playsInline id="guide-video">
          <source src="" type="video/webm" />
          <source src="" type="video/mp4" />
          <source src="" type="video/quicktime" />
          <track label="English" kind="subtitles" srcLang="en" src="" default />
        </GuideVideo>
        <GuideVideoClose id="guide-video-close" tabIndex={0} />
      </GuideVideoContainer>

      <MapButton
        onClick={() => {
          setShowMap(true);
        }}
        tabIndex={0}
        id="map-button"
      />
      <Map show={showMap} setShowMap={setShowMap} />

      <ArrowContainer>
        <Arrow
          onClick={() => {
            dispatchCameraMove('ccw');
          }}
          dir="left"
          tabIndex={0}
        />
        {room.type === '3D' && (
          <Arrow
            onClick={() => {
              dispatchCameraMove('forward');
            }}
            dir="up"
            tabIndex={0}
          />
        )}
        <Arrow
          onClick={() => {
            dispatchCameraMove('cw');
          }}
          dir="right"
          tabIndex={0}
        />
      </ArrowContainer>
      <MarkerToggleContainer onClick={toggleMarkers} tabIndex={0}>
        <MarkerToggleBar />
        <MarkerToggleText markersShowing={markersShowing}>
          {markersShowing ? 'ON' : 'OFF'}
        </MarkerToggleText>
        <MarkerToggleIcon markersShowing={markersShowing} />
      </MarkerToggleContainer>
    </UIContainer>
  );
};
