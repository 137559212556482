import { eventHandler } from '@functions/eventHandler';

export const sequenceHandler = (
  sequenceID: string,
  currentRoom: any = null,
  history?: any,
  marker?: HTMLDivElement
): void => {
  if (!currentRoom) return;
  const { scripting, events: eventList } = currentRoom;
  const { sequences } = scripting;
  if (sequences === null) return;
  const chosenSequence = sequences.filter(
    (sequence) => sequence.id === sequenceID
  )[0];
  console.log({ sequenceID });
  const chosenEvents = chosenSequence.events;
  let eventIndex = 0;
  const triggerNextEvent = () => {
    eventIndex++;
    if (chosenEvents[eventIndex])
      eventHandler(chosenEvents[eventIndex], eventList, history);
    else {
      window.removeEventListener('next-event', triggerNextEvent);
      if (marker) {
        marker.classList.add('opened');
      }
    }
  };
  window.addEventListener('next-event', triggerNextEvent);
  eventHandler(chosenEvents[eventIndex], eventList, history);
};
