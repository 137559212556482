import React from 'react';
import {
  OnboardingContainer,
  InnerContainer,
  ContentContainer,
  Title,
  Loader,
  Progress,
  Introduction,
  StartButton,
} from './Onboarding.styled';

import { useSelector } from 'react-redux';
import { selectTourName, selectTourStartRoom } from '@store/tour';
import { CSSTransition } from 'react-transition-group';
import { useRouteMatch, useParams } from 'react-router-dom';

import { updateCurrentTour } from '@functions/updateCurrentTour';

export const Onboarding = () => {
  const [percent, setPercent] = React.useState(0);
  const tourName = useSelector(selectTourName);
  const startRoom = useSelector(selectTourStartRoom);
  const { tour: tourID } = useParams<{ tour: string }>();
  const matches = useRouteMatch();
  let url = '';
  if (matches) url = matches.url;

  const contentRef1 = React.useRef(null);
  const contentRef2 = React.useRef(null);

  React.useEffect(() => {
    updateCurrentTour(tourID);
  }, []);

  React.useEffect(() => {
    if (percent < 100) {
      setTimeout(() => {
        setPercent(percent + 10);
      }, 200);
    }
  }, [percent]);

  return (
    <OnboardingContainer data-testid="Onboarding">
      <InnerContainer>
        <CSSTransition
          appear
          in={percent < 100}
          classNames="fade"
          timeout={300}
          unmountOnExit
          nodeRef={contentRef1}
        >
          <ContentContainer ref={contentRef1}>
            <Title>
              Welcome to the <span>{tourName}</span>
            </Title>
            {/* <Loader />
            <Progress>
              <p className="percent">{percent}%</p>
              <p>validating ticket</p>
            </Progress> */}
          </ContentContainer>
        </CSSTransition>

        <CSSTransition
          appear
          in={percent >= 100}
          classNames="fade"
          timeout={{ enter: 600, exit: 300 }}
          unmountOnExit
          nodeRef={contentRef2}
        >
          <ContentContainer ref={contentRef2}>
            <Introduction>
              <h2 className="heading">
                This experience<br></br> uses audio
              </h2>
              <img src="./img/ui/icon-headphones.png" alt="" />
              <p>For best results, put headphones on now</p>
            </Introduction>
            <StartButton to={`${url}/${startRoom}`}>Go to Tour</StartButton>
          </ContentContainer>
        </CSSTransition>
      </InnerContainer>
    </OnboardingContainer>
  );
};
