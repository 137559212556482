import { Mesh, PlaneGeometry, ShadowMaterial } from 'three';

export const add3DModelToScene = (
  modelContainer,
  position,
  lookAt,
  scale = 1,
  rotate = false,
  scene
): void => {
  modelContainer.scale.set(scale, scale, scale);
  modelContainer.position.set(position.x, position.y, position.z);
  modelContainer.lookAt(lookAt.x, lookAt.y, lookAt.z);
  scene.add(modelContainer);

  if (rotate) {
    setInterval(() => {
      modelContainer.rotateY(0.001);
      window.viewer.needsUpdate();
    }, 1);
  }
};
