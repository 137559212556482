import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

// import store from '@store/store';

export const loadGLBModel = (
  filename: string,
  id: string,
  castShadow = true
): THREE.Object3D => {
  const loader = new GLTFLoader();
  const container = new THREE.Group();
  container.name = id;

  loader.setPath('/models');
  loader.load(
    `/${filename}.glb`,
    (object) => {
      setTimeout(() => {
        object.scene.traverse((child) => {
          if (child instanceof THREE.Mesh && castShadow) {
            child.castShadow = true;
            // child.receiveShadow = true;
          }
        });
        container.add(object.scene);
        if (window.viewer) {
          window.viewer.needsUpdate();
        }
      }, 2000);
    },
    (xhr: ProgressEvent) => {
      if (xhr.lengthComputable) {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      }
      // } else if (size) {
      //   const loadAmount = Math.round((xhr.loaded / size) * 100);
      //   store.dispatch({
      //     type: 'rooms/setRoomModelLoadedAmount',
      //     payload: { filename, loadAmount },
      //   });
      // } else {
      //   console.log(xhr.loaded + ' bytes loaded');
      // }
    },
    // called when loading has errors
    (error) => {
      console.log('An error happened: ', error);
    }
  );
  return container;
};
