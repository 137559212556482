import styled from 'styled-components';

export const UIContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.5s ease;
  pointer-events: none;
  z-index: 25;

  > * {
    pointer-events: all;
  }

  &.hide {
    opacity: 0;
  }
`;

export const TitleContainer = styled.div<{ slug: string }>`
  width: 420px;
  height: 130px;
  background-image: ${(props) =>
    `url('/img/ui/title-desktop-${props.slug}@2x.png')`};
  background-size: contain;
  background-position: center;

  @media only screen and (max-width: 1024px) {
    transform: scale(0.5);
  }
`;

export const TitleTour = styled.h2`
  position: relative;
  top: 20px;
  margin: 0;
  font-family: 'Argesta Headline';
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
`;

export const TitleRoom = styled.h1`
  position: relative;
  top: 44px;
  margin: 0;
  font-family: 'Argesta Headline';
  font-size: 22px;
  color: #000;
  text-transform: uppercase;
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 1024px) {
    transform: scale(0.5);
  }
`;

export const Arrow = styled.div<{ dir: string }>`
  position: relative;
  bottom: ${(props) => (props.dir === 'up' ? '50px' : '0')};
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #e6e6e6;
  background-image: url('/img/ui/arrow.svg');
  background-size: 130px;
  background-repeat: no-repeat;
  background-position: center;
  transform: ${(props) => {
    if (props.dir === 'left') return 'rotate(180deg)';
    else if (props.dir === 'up') return 'rotate(-90deg)';
    else return '';
  }};
  transition: background 0.3s ease;

  &:hover {
    cursor: pointer;
    background-color: #fff;
  }
`;

export const Use = styled.p`
  pointer-events: all;
  margin: 0;
  padding: 20px;
  background-color: grey;
  border: 3px solid white;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  font-size: 28px;

  &:hover {
    cursor: pointer;
  }
`;

export const MenuButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;

  img {
    width: 200px;
  }

  @media only screen and (max-width: 1024pxx) {
    transform: scale(0.5);

    img {
      width: 80px;
    }
  }
`;

export const GuideVideoContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc((500 / 1920) * 100%);
  z-index: 2;
  transition: bottom 1s ease;

  &.hide {
    bottom: -600px;
    pointer-events: none;
  }
`;
export const GuideVideo = styled.video`
  width: 100%;
`;

export const MarkerToggleContainer = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 150px;
  height: 60px;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 1024px) {
    transform: scale(0.5);
  }
`;

export const MarkerToggleBar = styled.div`
  position: absolute;
  top: 10px;
  width: 150px;
  height: 50px;
  border-radius: 25px;
  background-color: #e6e6e6;
`;

export const MarkerToggleIcon = styled.div<{ markersShowing: boolean }>`
  position: absolute;
  top: 5px;
  right: ${(props) => (props.markersShowing ? '10px' : '80px')};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  background-image: ${(props) =>
    props.markersShowing
      ? 'url("/img/ui/button-info-gold.svg")'
      : 'url("/img/ui/button-info-black.svg")'};
  background-size: 90px;
  background-position: center;
  transition: right 0.5s ease, background 0.5s ease;

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 2.5px);
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #000;
    transform: rotate(-45deg);
    transition: opacity 0.5s ease;
    opacity: ${(props) => (props.markersShowing ? '0' : '1')};
  }
`;

export const MarkerToggleText = styled.p<{ markersShowing: boolean }>`
  position: absolute;
  top: 23px;
  left: ${(props) => (props.markersShowing ? '25px' : '85px')};
  margin: 0;
  font-family: 'Argesta Text';
  font-weight: bold;
  font-size: 22px;
  transition: left 0.5s ease;
`;

export const GuideVideoClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  background-image: url('/img/ui/button-close-normal.svg');
  background-position: center;
  transition: background 0.5s ease;

  &:hover {
    background-image: url('/img/ui/button-close-hover.svg');
    cursor: pointer;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  padding: 25px;
  background-color: #fff;
  transition: left 1s ease;
  z-index: 5;

  &.open {
    left: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;

    &__title {
      font-family: 'Argesta Text';
      font-weight: bold;
      font-size: 22px;
      text-transform: uppercase;
    }

    &__button {
      width: 50px;
      height: 50px;
      background-image: url('/img/ui/button-close-dark-normal.svg');
      background-position: center;
      transition: background 0.5s ease;

      &:hover {
        background-image: url('/img/ui/button-close-dark-hover.svg');
        cursor: pointer;
      }
    }
  }

  .menu-options {
    .option {
      display: flex;
      align-items: center;
      margin: 20px 0;

      &:first-of-type {
        margin-top: 50px;
      }

      .checkbox {
        width: 50px;
        height: 50px;
        background-image: url('/img/ui/checkbox-ticked.svg');
        background-position: center;
        transition: background 0.5s ease;

        &:hover {
          cursor: pointer;
        }

        &.off {
          background-image: url('/img/ui/checkbox-crossed.svg');
        }
      }

      p {
        margin: 0;
        font-family: 'Argesta Text';
        font-size: 20px;
        text-transform: uppercase;
        text-align: left;
      }
    }
  }

  .exit-tour {
    position: absolute;
    bottom: 60px;
    width: 80%;
    margin: 0;
    font-family: 'Argesta Text';
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;
    background-image: url('/img/ui/arrow.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: 60px;
    border: 1px solid black;
    padding: 10px;
    transition: background 0.5s ease;
    background-color: white;

    &:hover {
      background-color: lightgrey;
      cursor: pointer;
    }
  }
`;

export const MenuToggle = styled.div`
  position: absolute;
  top: 35px;
  left: 35px;
  width: 60px;
  height: 60px;
  background-image: url('/img/ui/button-menu-normal.svg');
  background-position: center;
  transition: background 0.5s ease;

  &:hover {
    background-image: url('/img/ui/button-menu-hover.svg');
    cursor: pointer;
  }

  @media only screen and (max-width: 1024px) {
    transform: scale(0.5);
  }
`;

export const MapButton = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 150px;
  height: 60px;
  background-image: url('/img/ui/button-map-normal.svg');
  background-position: center;
  background-repeat: no-repeat;
  transition: background 0.5s ease, opacity 0.5s ease;
  opacity: 1;

  &:hover {
    background-image: url('/img/ui/button-map-hover.svg');
    cursor: pointer;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  @media only screen and (max-width: 1024px) {
    transform: scale(0.5);
  }
`;
