import {
  Scene,
  AmbientLight,
  DirectionalLight,
  PlaneGeometry,
  ShadowMaterial,
  Mesh,
  PointLight,
  SpotLight,
  DirectionalLightHelper,
  SpotLightHelper,
} from 'three';

export const addLights = (scene: Scene, lights: any): void => {
  const ambientLight = new AmbientLight(0xba9575, 0.5);
  scene.add(ambientLight);

  const pointLight = new PointLight(0xba9575, 0.3);
  scene.add(pointLight);

  lights.forEach((light) => {
    let newLight;
    if (light.type === 'directional') {
      newLight = new DirectionalLight(0xffffff, 1);
      newLight.shadow.camera.left = -50;
      newLight.shadow.camera.right = 50;
      newLight.shadow.camera.top = 50;
      newLight.shadow.camera.bottom = -50;

      if (light.helper) {
        const helper = new DirectionalLightHelper(newLight, 5);
        scene.add(helper);
      }
    } else if (light.type === 'spotlight') {
      newLight = new SpotLight(0xffffff, 1);

      if (light.helper) {
        const helper = new SpotLightHelper(newLight);
        scene.add(helper);
      }
    }
    newLight.position.set(light.position.x, light.position.y, light.position.z);
    newLight.castShadow = true;
    newLight.shadow.mapSize.width = 1024;
    newLight.shadow.mapSize.height = 1024;
    newLight.shadow.camera.far = 100;
    scene.add(newLight);

    newLight.target.position.set(
      light.target.x,
      light.target.y,
      light.target.z
    );
    scene.add(newLight.target);
  });

  const floor = new Mesh(
    new PlaneGeometry(150, 150),
    new ShadowMaterial({ opacity: 0.3 })
  );
  floor.rotation.x = -Math.PI / 2;
  floor.position.set(0, -12, 0);
  floor.name = 'floor';
  floor.receiveShadow = true;
  scene.add(floor);
};
