export const textureCoordsToSphericalCoords = (
  x = 0,
  y = 0,
  fullWidth = 4000,
  fullHeight = 2000,
  croppedX = 0,
  croppedY = 0
): {
  longitude: number;
  latitude: number;
} => {
  const relativeX = ((x + croppedX) / fullWidth) * Math.PI * 2;
  const relativeY = ((y + croppedY) / fullHeight) * Math.PI;
  return {
    longitude: relativeX >= Math.PI ? relativeX - Math.PI : relativeX + Math.PI,
    latitude: Math.PI / 2 - relativeY,
  };
};
