import React from 'react';
import {
  TourSelectContainer,
  InnerContainer,
  Title,
  TourLink,
} from './TourSelect.styled';

import { TourListItem } from '@store/tour';

export const TourSelect = () => {
  const [tourList, setStateTourList] = React.useState<TourListItem[]>([]);

  React.useEffect(() => {
    fetch(`./tours/tour-list.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setStateTourList(json.tours);
      });
  }, []);
  return (
    <TourSelectContainer data-testid="TourSelect">
      <InnerContainer>
        <Title>Select a tour</Title>
        {tourList.map((tour: TourListItem) => (
          <TourLink key={tour.name} to={`${tour.name}`}>
            {tour.title}
          </TourLink>
        ))}
      </InnerContainer>
    </TourSelectContainer>
  );
};
