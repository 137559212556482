import React from 'react';
import {
  PhotosphereContainer,
  ModalWrapper,
  ModalContainer,
  ModalClose,
  ModalTitle,
  ModalContent,
  ModalImage,
  ModalText,
  DynamicImageWrapper,
} from './Photosphere.styled';

import './psv.css';

import { Viewer } from 'photo-sphere-viewer';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
import VisibleRangePlugin from 'photo-sphere-viewer/dist/plugins/visible-range';
import GyroscopePlugin from 'photo-sphere-viewer/dist/plugins/gyroscope';
import StereoPlugin from 'photo-sphere-viewer/dist/plugins/stereo';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/store';
import { storeLookPoint } from '@store/cameraPhotosphere';
import {
  RoomInformation,
  checkDistanceToMarkersPhotosphere,
  selectModalInfo,
  selectDynamicImageInfo,
  selectClosestMarker,
} from '@store/rooms';

import { sequenceHandler } from '@functions/sequenceHandler';

import { textureCoordsToSphericalCoords } from '@functions/textureCoordsToSphericalCoords';
import { add3DModelToScene } from '@functions/add3DModelToScene';
import { addLights } from '@functions/addLights';

interface Props {
  room: RoomInformation;
}

let viewer, markersPlugin;

export const Photosphere = ({ room }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const modalInfo = useSelector(selectModalInfo);
  const dyamicImageInfo = useSelector(selectDynamicImageInfo);
  const closestMarker = useSelector(selectClosestMarker);

  const closeModal = () => {
    const modalWrapper = document.getElementById('modal-wrapper');
    modalWrapper?.classList.add('hide');
    window.dispatchEvent(new Event('next-event'));
  };

  const handleMarkerClick = (e: Event) => {
    if (
      e.target instanceof HTMLDivElement &&
      e.target.classList.contains('marker')
    ) {
      const marker = e.target;
      const sequence = marker.getAttribute('data-sequence');
      if (sequence) {
        sequenceHandler(sequence, room, history, marker);
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ') {
      const closestMarker = document.querySelector('.psv-marker.closest');
      if (!closestMarker) return;
      else if (closestMarker instanceof HTMLElement) {
        const closestID = closestMarker.id.replace('psv-marker-', '');
        const markerObject = markersPlugin.markers[closestID];
        if (markerObject.data) {
          const innerMarker = markerObject.$el.children[0];
          sequenceHandler(
            markerObject.data.sequence,
            room,
            history,
            innerMarker
          );
        }
      }
    }
  };

  const buildMarkers = (roomMarkers) => {
    const markers = roomMarkers.map((roomMarker) => {
      const {
        id,
        position,
        sequence,
        type,
        tooltip,
        visible = true,
      } = roomMarker;
      let html = '';
      let size = 60;
      switch (type) {
        case 'doorway-forward':
          html = `<div class="marker doorway-forward" data-sequence="${sequence}"></div>`;
          break;
        case 'doorway-back':
          html = `<div class="marker doorway-back" data-sequence="${sequence}"></div>`;
          break;
        case 'go-to-present':
          html = `<div class="marker go-to-present" data-sequence="${sequence}"></div>`;
          break;
        case 'go-to-past':
          html = `<div class="marker go-to-past" data-sequence="${sequence}"></div>`;
          break;
        case 'guide-video':
        case 'modal':
          html = `<div class="marker info" data-sequence="${sequence}"></div>`;
          size = 40;
          break;
        default:
          break;
      }
      return {
        id,
        x: position.x,
        y: position.y,
        width: size,
        height: size,
        html: html,
        data: {
          sequence,
        },
        visible,
        tooltip: tooltip || null,
      };
    });
    return markers;
  };

  React.useEffect(() => {
    let sphericalStartCoords = {
      latitude: 0,
      longitude: 0,
    };
    if (room.photosphereDetails?.startLook) {
      sphericalStartCoords = textureCoordsToSphericalCoords(
        room.photosphereDetails.startLook.x || 0,
        room.photosphereDetails.startLook.y || 0,
        4096,
        2048,
        0,
        0
      );
    }

    viewer = new Viewer({
      container: document.querySelector('#photosphere'),
      panorama: `/img/panorama/${room.photosphereDetails?.filename}`,
      moveInertia: true,
      defaultZoomLvl: 0,
      defaultLong: sphericalStartCoords.longitude,
      defaultLat: sphericalStartCoords.latitude,
      maxFov: 80,
      // navbar: [],
      keyboard: false,
      plugins: [
        [
          MarkersPlugin,
          {
            // list of markers
            markers: buildMarkers(room.scripting.markers),
          },
        ],
        [
          VisibleRangePlugin,
          {
            latitudeRange: ['-180deg', '179deg'],
          },
        ],
        GyroscopePlugin,
        StereoPlugin,
      ],
    });

    window.viewer = viewer;

    viewer.on('ready', () => {
      const {
        scene,
        renderer,
      }: {
        scene: THREE.Scene;
        renderer: THREE.WebGLRenderer;
      } = viewer.renderer;

      renderer.shadowMap.enabled = true;
      // renderer.sortObjects = false;
      if (room.lights) {
        addLights(scene, room.lights);
      }
      if (room.models) {
        room.models.forEach((model) => {
          const modelContainer = window.models.filter(
            (windowModel) => windowModel.id === model.id
          )[0];
          add3DModelToScene(
            modelContainer.dataObject,
            model.position,
            model.lookAt,
            model.scale,
            model.rotate,
            scene
          );
        });
      }
      // const roomEl = document.getElementById('room');
      // if (roomEl) {
      //   roomEl.classList.remove('cover-black');
      // }
    });

    viewer.on('position-updated', (e, position) => {
      dispatch(
        storeLookPoint({ lat: position.latitude, lon: position.longitude })
      );
      dispatch(
        checkDistanceToMarkersPhotosphere({
          lat: position.latitude,
          lon: position.longitude,
        })
      );
    });

    markersPlugin = viewer.getPlugin(MarkersPlugin);
    window.markersPlugin = markersPlugin;

    // markersPlugin.on(
    //   'select-marker',
    //   (
    //     e: Event,
    //     marker: { $el: { children: HTMLDivElement[] }; data: { sequence } }
    //   ) => {
    //     console.log({ marker });
    //     if (marker.data) {
    //       const innerMarker = marker.$el.children[0];
    //       sequenceHandler(marker.data.sequence, room, history, innerMarker);
    //     }
    //   }
    // );

    document.addEventListener('click', handleMarkerClick);

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('click', handleMarkerClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    const setNewClosest = () => {
      const newClosestMarker = document.getElementById(
        `psv-marker-${closestMarker.id}`
      );
      newClosestMarker?.classList.add('closest');
    };

    const currentClosestMarker = document.querySelector('.closest');
    if (closestMarker.distance < 0.5) {
      if (!currentClosestMarker) {
        setNewClosest();
      } else {
        const currentMarkerID = currentClosestMarker.id.replace(
          'psv-marker-',
          ''
        );
        if (currentMarkerID !== closestMarker.id) {
          currentClosestMarker.classList.remove('closest');
          setNewClosest();
        }
      }
    } else {
      const currentClosestMarker = document.querySelector('.closest');
      currentClosestMarker?.classList.remove('closest');
    }
  }, [closestMarker]);

  return (
    <React.Fragment>
      <PhotosphereContainer id="photosphere" />

      <ModalWrapper id="modal-wrapper" className="hide">
        <ModalContainer>
          <ModalClose
            id="modal-close"
            onClick={() => {
              closeModal();
            }}
          />
          <ModalTitle>
            <h2>{room.title}</h2>
            <h1>{modalInfo.title}</h1>
          </ModalTitle>
          <ModalContent dual={!!modalInfo.image && !!modalInfo.text}>
            {modalInfo.image && (
              <ModalImage
                src={`/img/tour-content/${modalInfo.image || ''}`}
                alt=""
                dual={!!modalInfo.image && !!modalInfo.text}
              />
            )}
            {modalInfo.text && (
              <ModalText dual={!!modalInfo.image && !!modalInfo.text}>
                {modalInfo.text}
              </ModalText>
            )}
          </ModalContent>
        </ModalContainer>
      </ModalWrapper>

      <DynamicImageWrapper
        id="dynamic-image"
        dimensions={{
          top: dyamicImageInfo.position.y,
          left: dyamicImageInfo.position.x,
          width: dyamicImageInfo.size.width,
          height: dyamicImageInfo.size.height,
        }}
        className="hide"
      >
        <img
          id="dynamic-image"
          src={`/img/tour-content/${dyamicImageInfo.source}`}
          alt=""
        />
      </DynamicImageWrapper>
    </React.Fragment>
  );
};
