/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vector3 } from 'three';
import { RootState } from './rootReducer';

interface Position {
  x: number;
  y: number;
  z: number;
}

interface Position2D {
  x: number;
  y: number;
}

interface LatLon {
  lat: number;
  lon: number;
}

export interface SceneMarker {
  name: string;
  type: string;
  action: string;
  position: Position;
}

export interface ModelInformation {
  filename: string;
  position: Position;
  size?: number;
  loadAmount?: number;
  ready?: boolean;
  markers: SceneMarker[];
}

interface PhotosphereMarker {
  id: string;
  x: number;
  y: number;
  action: string;
  tooltip?: string;
}

export interface PhotosphereInformation {
  filename: string;
  startLook: Position2D;
}

export interface Event {
  id: string;
  type: string;
  wait: boolean;
  position: Position;
  source?: string;
  room?: string;
  direction?: {
    x?: number;
    y?: number;
    z?: number;
  };
  rotate?: boolean;
  lightID?: string;
  action?: string;
  waitTime?: number;
  colour?: string;
  subtitles?: boolean;
  modalInfo?: ModalInfo;
  dynamicImage?: DynamicImage;
}

export interface LightObject {
  id: string;
  type: string;
  color?: string;
  intensity?: number;
  position?: Position;
  target?: Position;
  helper?: boolean;
}

export interface RoomInformation {
  id: string;
  title: string;
  type: '3D' | 'photosphere';
  modelDetails?: ModelInformation;
  photosphereDetails?: PhotosphereInformation;
  scripting: any;
  events: Event[];
  lights: LightObject[];
  audio?: string;
  startPosition?: Position;
  startLook?: Position;
  models: any;
  light?: {
    position: Position;
    target: Position;
    scale: number;
  };
}

export interface ClosestMarker {
  id: string;
  distance: number;
}
export interface DynamicImage {
  source: string;
  position: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
  time?: number;
}

export interface Tour {
  rooms: RoomInformation[];
  currentRoom: string;
  closestMarker: ClosestMarker;
  hoverActive: boolean;
  inputAllowed: boolean;
  displayUI: boolean;
  modalInfo: ModalInfo;
  dynamicImageInfo: DynamicImage;
}
export interface Sequence {
  id: string;
  autoplay: boolean;
  repeat: boolean;
  events: string[];
}

export interface ModalInfo {
  title: string;
  text?: string;
  image?: string;
}

const initialState: Tour = {
  rooms: [],
  currentRoom: '',
  closestMarker: {
    id: '',
    distance: Infinity,
  },
  hoverActive: false,
  inputAllowed: true,
  displayUI: true,
  modalInfo: {
    title: '',
    text: '',
    image: '',
  },
  dynamicImageInfo: {
    source: '',
    position: {
      x: 0,
      y: 0,
    },
    size: {
      width: 0,
      height: 0,
    },
    time: 0,
  },
};

export const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setRooms: (state, action: PayloadAction<Tour>) => {
      state.rooms = action.payload.rooms;
    },
    setRoomModelLoadedAmount: (
      state,
      action: PayloadAction<ModelInformation>
    ) => {
      state.rooms = state.rooms.map((room) => {
        if (room.modelDetails?.filename !== action.payload.filename)
          return room;
        else {
          return {
            ...room,
            modelDetails: {
              ...room.modelDetails,
              loadAmount: action.payload.loadAmount,
            },
          };
        }
      });
    },
    setRoomModelReady: (state, action: PayloadAction<ModelInformation>) => {
      state.rooms = state.rooms.map((room) => {
        if (room.modelDetails?.filename !== action.payload.filename)
          return room;
        else {
          return {
            ...room,
            modelDetails: {
              ...room.modelDetails,
              ready: action.payload.ready,
            },
          };
        }
      });
    },
    setCurrentRoom: (state, action: PayloadAction<string>) => {
      state.currentRoom = action.payload;
    },
    checkDistanceToMarkers: (state) => {
      const currentRoom = state.rooms.filter(
        (room) => room.id === state.currentRoom
      )[0];
      const loadedMarkers: SceneMarker[] | PhotosphereMarker[] | [] =
        currentRoom.scripting.markers;
      const { camera } = window;
      let shortestDistance;
      let closestMarker = '';
      loadedMarkers.forEach((marker) => {
        const markerPosition = new Vector3(
          marker.position.x,
          marker.position.y,
          marker.position.z
        );
        const distance = camera.position.distanceTo(markerPosition);
        if (!shortestDistance || distance < shortestDistance) {
          shortestDistance = distance;
          closestMarker = marker.name;
        }
      });
      state.closestMarker.id = closestMarker;
      state.closestMarker.distance = shortestDistance;
    },
    checkDistanceToMarkersPhotosphere: (
      state,
      action: PayloadAction<LatLon>
    ) => {
      const { markersPlugin } = window;
      if (!markersPlugin) return;
      const { lat: cameraLat, lon: cameraLon } = action.payload;
      let shortestDistance;
      let closestMarker = '';
      const { markers } = markersPlugin;
      Object.keys(markers).forEach((key) => {
        const marker = markers[key];
        const markerLat = marker.props.position.latitude;
        const markerLon = marker.props.position.longitude;
        const x =
          (markerLon - cameraLon) * Math.cos((cameraLat + markerLat) / 2);
        const y = markerLat - cameraLat;
        const distance = Math.sqrt(x * x + y * y);
        if (!shortestDistance || distance < shortestDistance) {
          shortestDistance = distance;
          closestMarker = marker.id;
        }
      });

      state.closestMarker.id = closestMarker;
      state.closestMarker.distance = shortestDistance;
    },
    setHoverActive: (state, action: PayloadAction<boolean>) => {
      state.hoverActive = action.payload;
    },
    setInputAllowed: (state, action: PayloadAction<boolean>) => {
      state.inputAllowed = action.payload;
    },
    setUIDisplayState: (state, action: PayloadAction<boolean>) => {
      state.displayUI = action.payload;
    },
    setModalInfo: (state, action: PayloadAction<ModalInfo>) => {
      state.modalInfo.title = action.payload.title;
      if (action.payload.text) {
        state.modalInfo.text = action.payload.text;
      } else {
        state.modalInfo.text = '';
      }
      if (action.payload.image) {
        state.modalInfo.image = action.payload.image;
      } else {
        state.modalInfo.image = '';
      }
    },
    setDynamicImageInfo: (state, action: PayloadAction<DynamicImage>) => {
      state.dynamicImageInfo = action.payload;
    },
  },
});

export const {
  setRooms,
  setRoomModelLoadedAmount,
  setRoomModelReady,
  setCurrentRoom,
  checkDistanceToMarkers,
  checkDistanceToMarkersPhotosphere,
  setInputAllowed,
  setModalInfo,
  setUIDisplayState,
  setDynamicImageInfo,
} = roomsSlice.actions;

export const select3DRooms = (state: RootState): RoomInformation[] =>
  state.rooms.rooms.filter((room) => room.type === '3D');

export const selectPhotosphereRooms = (state: RootState): RoomInformation[] =>
  state.rooms.rooms.filter((room) => room.type === 'photosphere');

export const selectCurrentRoom = (state: RootState): RoomInformation =>
  state.rooms.rooms.filter((room) => room.id === state.rooms.currentRoom)[0];

export const selectClosestMarker = (state: RootState): ClosestMarker =>
  state.rooms.closestMarker;

export const selectHoverActive = (state: RootState): boolean =>
  state.rooms.hoverActive;

export const selectCurrentSequences = (state: RootState): Sequence[] | null => {
  const currentRoom = state.rooms.rooms.filter(
    (room) => room.id === state.rooms.currentRoom
  )[0];
  if (currentRoom) return currentRoom.scripting.sequences;
  else return null;
};

export const selectCurrentEvents = (state: RootState): Event[] => {
  const currentRoom = state.rooms.rooms.filter(
    (room) => room.id === state.rooms.currentRoom
  )[0];
  return currentRoom.events;
};

export const selectInputAllowed = (state: RootState): boolean => {
  return state.rooms.inputAllowed;
};

export const selectUIDisplayState = (state: RootState): boolean => {
  return state.rooms.displayUI;
};

export const selectDynamicImageInfo = (state: RootState): DynamicImage => {
  return state.rooms.dynamicImageInfo;
};

export const selectModalInfo = (state: RootState): ModalInfo => {
  return state.rooms.modalInfo;
};

export default roomsSlice.reducer;
