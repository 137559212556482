import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AppContainer, PortraitWarning } from './App.styled';
import './fonts/fonts.css';

import { CSSTransition } from 'react-transition-group';
import { isMobile } from 'react-device-detect';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/store';
import {
  selectTourID,
  setTourName,
  setTourSlug,
  setTourStartRoom,
  setRooms,
  setModels,
  selectVerified,
  setDeviceType,
} from '@store/tour';

import TourSelect from '@pages/TourSelect';
import Onboarding from '@pages/Onboarding';
import Room from '@pages/Room';
import PageNotFound from '@pages/PageNotFound';
import { loadGLBModel } from '@functions/loadGLBModel';
import Authenticate from '@components/Authenticate';

declare global {
  interface Window {
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
    viewer: any;
    lat: number;
    lon: number;
    getLatLon: any;
    markersPlugin: any;
    tourName: string;
    tourSlug: string;
    modalText: string;
    modalImage: string;
    timeout: number;
    models: any;
  }
}

const App = () => {
  const dispatch = useAppDispatch();
  const tourID = useSelector(selectTourID);
  const verified = useSelector(selectVerified);

  const selectRef = React.useRef(null);
  const onboardingRef = React.useRef(null);
  const roomRef = React.useRef(null);

  React.useEffect(() => {
    if (tourID) {
      fetch(`/tours/${tourID}.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Not 2xx response');
          } else {
            return response.json();
          }
        })
        .then((json) => {
          dispatch(setTourName(json.name));
          dispatch(setTourStartRoom(json.startRoom));
          dispatch(setRooms(json.rooms));
          dispatch(setTourSlug(json.slug));
          dispatch(setModels(json.models));

          const { models } = json;
          if (!window.models && models) {
            const dataLoadedModels = models.map((model) => {
              const dataObject = loadGLBModel(
                model.filename,
                model.id,
                model.castShadow
              );
              return { ...model, dataObject: dataObject };
            });
            window.models = dataLoadedModels;
          }
        })
        .catch((error) => {
          console.error('Error fetching tour JSON: ', error);
          // window.location.pathname = '/404';
        });
    }
  }, [tourID]);

  React.useEffect(() => {
    if (isMobile) {
      dispatch(setDeviceType('mobile'));
    }
  }, []);

  // if (!verified) return <Authenticate />;

  return (
    <React.Fragment>
      <PortraitWarning className="portrait-warning">
        <h1>Please rotate your device to landscape</h1>
      </PortraitWarning>
      <AppContainer className="main-app">
        <Router>
          <Route exact path="/">
            {({ match }) => (
              <CSSTransition
                in={match !== null}
                timeout={{ enter: 600, exit: 300 }}
                classNames="fade"
                unmountOnExit
                nodeRef={selectRef}
              >
                <div ref={selectRef}>
                  <TourSelect />
                </div>
              </CSSTransition>
            )}
          </Route>
          <Route exact path="/:tour">
            {({ match }) => {
              if (match && match.url === '/404') return <PageNotFound />;
              else
                return (
                  <CSSTransition
                    in={match !== null}
                    timeout={{ enter: 600, exit: 300 }}
                    classNames="fade"
                    unmountOnExit
                    nodeRef={onboardingRef}
                  >
                    <div ref={onboardingRef}>
                      <Onboarding />
                    </div>
                  </CSSTransition>
                );
            }}
          </Route>
          <Route exact path="/:tour/:room">
            {({ match }) => (
              <CSSTransition
                in={match !== null}
                timeout={{ enter: 600, exit: 300 }}
                classNames="fade"
                unmountOnExit
                nodeRef={roomRef}
              >
                <div ref={roomRef}>
                  <Room key={window.location.pathname} />
                </div>
              </CSSTransition>
            )}
          </Route>
        </Router>
      </AppContainer>
    </React.Fragment>
  );
};

export default App;
